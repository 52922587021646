import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { NewYear, PromoPopup, RecallsGif } from "../../../assets";
import { Link } from "react-router-dom";
import DailyCounter from "../DailyCounter";

const LoadModal = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  useEffect(() => {
    const timer = setTimeout(onOpen, 5000);
    return () => clearTimeout(timer);
  }, [onOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size={"3xl"}
      placement={"center"}
    >
      <ModalContent>
        {(onCloseModal) => (
          <>
            <div className="relative bg-themeButton-0 px-4 py-7 md:p-8 text-left ">
              {/* Custom Close Button */}
              <button
                onClick={onCloseModal}
                className="absolute top-3 right-3 text-white font-bold w-4 h-4"
                aria-label="Close"
              >
                ✕
              </button>
              <ModalBody className="p-0">
                <h2 className="text-white text-lg md:text-[22px] font-black text-center ">
                  🎉 INTRODUCING THE AMC PART 1 RECALLS BANK! 🎉{" "}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mb-2">
                  <div className="text-white font-bold flex flex-col">
                    <p className="mb-4 text-sm font-bold">
                    Get access to our comprehensive AMC Part 1 Recalls Bank at a special launch price.
                    </p>
                    <p className="mb-3 text-sm font-bold">
                      Save big with our special 50% discount plus an EXTRA 25%
                      off when you use code:{" "}
                      <span className="bg-white text-themeButton-0 uppercase inline-block font-bold">
                        Gift25
                      </span>
                    </p>
                    <p className="mb-4 text-sm font-bold">
                      🌟 Limited Time Offer! 🌟
                    </p>
                    <div className="flex flex-row gap-2 items-center mb-4">
                      <div className="text-sm font-bold">
                        <span className="text-sm font-bold">Sale Price : </span>
                        <span className="line-through text-sm font-bold text-white opacity-45">
                          $80
                        </span>
                      </div>
                      <span className="text-white bg-themeSecondry-0 rounded-2xl py-1 px-2 inline-block blinking-text">
                        $40/ 1 Month
                      </span>
                    </div>
                    <DailyCounter targetDate="2025-04-09" />
                  </div>
                  <div className="hidden sm:block">
                    <img
                      width={1000}
                      height={1000}
                      src={RecallsGif}
                      alt="Sale"
                      className="w-full  object-fit"
                    />
                  </div>
                </div>
                <Link
                  to={`/subscriptions`}
                  className="hover:underline cursor-pointer w-full bg-themeSecondry-0 rounded-md font-bold text-center text-white py-3"
                >
                  Subscribe Now
                </Link>
                <div className="block sm:hidden">
                  <img
                    width={1000}
                    height={1000}
                    src={RecallsGif}
                    alt="Sale"
                    className="w-full  object-fit"
                  />
                </div>
              </ModalBody>
            </div>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default LoadModal;

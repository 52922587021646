import { Chip } from "@nextui-org/react";
import React, { useState, useEffect, useRef } from "react";

const Timer = ({ totalTime, setTimeConsumed, setExitRecall }) => {
  const [time, setTime] = useState(totalTime);
  const previousTimeRef = useRef(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          setExitRecall(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    setTimeConsumed((prevConsumedTime) => {
      const newTimeConsumed =
        totalTime - time + prevConsumedTime - previousTimeRef.current;
      previousTimeRef.current = totalTime - time;
      return newTimeConsumed;
    });
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds} minutes`;
  };

  return (
    <div className="flex gap-1 md:gap-2  ">
      <div className="flex flex-col justify-center items-center bg-themeButton-0 gap-0 rounded-[2px] md:rounded-md text-white py-1 px-1 md:px-3">
        <p className="text-lg font-bold leading-none mt-1">
          {Math.floor((time % 3600) / 60)
            .toString()
            .padStart(2, "0")}
        </p>
        <span className="text-[11px]  ">Minutes</span>
      </div>

      <p className="text-4xl text-themeButton-0">:</p>
      <div className="flex flex-col justify-center items-center bg-themeButton-0 rounded-[2px] md:rounded-md text-white py-1 px-1 md:px-3">
        <p className="text-lg font-bold leading-none mt-1">
          {(time % 60).toString().padStart(2, "0")}
        </p>
        <span className="text-[11px]">Seconds</span>
      </div>
    </div>
  );
};

export default Timer;

import React, { useEffect, useState } from "react";
import Tableform from "../../components/general/Tableform";
import { API } from "../../api";
import { errorToast, successToast } from "../../hooks/useToast";
import { timebasedRecallcolumn } from "../../data/timebasedRecallcolumn";
import { explanationBasedRecallcolumn } from "../../data/explanationBasedRecallcolumn";
import Loader from "../../components/general/Loader"; 
import MetaData from "../../components/seo/MetaData";
import LinkComponent3 from "../../components/general/LinkComponent3";

const MyRecallsPage = () => {
  const [itemPerPage, setItemPerPage] = useState(5);
  const [timebased, setTimeBased] = useState([]);
  const [explanationBased, setExplanationBased] = useState([]);
  const [loadingTimeBased, setLoadingTimeBased] = useState(true);
  const [loadingExplanationBased, setLoadingExplanationBased] = useState(true);

  const fetchData = async (mode, setState, setLoadingState) => {
    const params = new URLSearchParams({ mode }).toString();

    try {
      const response = await API.userRecall(params);

      if (response.status === 200) {
        setState(response.data.data || []);
      } else {
        errorToast("Failed to fetch data");
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
      errorToast("There has been a problem with your fetch operation");
    } finally {
      setLoadingState(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await API.deleteUserRecall(id);
      successToast("Deleted");
      fetchData("timeBased", setTimeBased, setLoadingTimeBased);
      fetchData(
        "explanationBased",
        setExplanationBased,
        setLoadingExplanationBased
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData("timeBased", setTimeBased, setLoadingTimeBased);
    fetchData(
      "explanationBased",
      setExplanationBased,
      setLoadingExplanationBased
    );
  }, []);

  return (
    <div className="flex flex-col">
      <MetaData
        title={"Manage Recalls - Qbank Model"}
        description={
          "Organize and schedule your recalls with Qbank Model. Access recall details and prepare efficiently for your tests."
        }
        keywords={"recall management, Qbank Model, schedule recalls, recall details"}
      />
      <div className="profile-header  py-4   interFont flex items-center w-full justify-between  ">
        <h2 className=" text-2xl interFont capitalize font-bold ">
          Your Recalls{" "}
        </h2>
        <LinkComponent3 to={"/user/recall"} text={"Create Recall"} />
      </div>

      {loadingExplanationBased ? (
        <Loader />
      ) : (
        <>
          <Tableform
            tableheading="Time Based"
            filterdata={timebased}
            tablecolumns={timebasedRecallcolumn}
            itemPerPage={itemPerPage}
            loading={loadingTimeBased}
            resultPreview={true}
            deletePreview={false}
            handleDelete={handleDelete}
            noContentText={"Create Your First Time Based Recall"}
            subscriptionType="recall"
          />

          <Tableform
            tableheading="Explanation Based"
            filterdata={explanationBased}
            tablecolumns={explanationBasedRecallcolumn}
            itemPerPage={itemPerPage}
            loading={loadingExplanationBased}
            resultPreview={true}
            deletePreview={false}
            handleDelete={handleDelete}
            noContentText={"Create Your First Explanation Based Recall"}
            subscriptionType="recall"
          />
        </>
      )}
    </div>
  );
};

export default MyRecallsPage;

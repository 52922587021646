import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recallData: null,
};

const recallSlice = createSlice({
  name: "recall",
  initialState,
  reducers: {
    createUserRecall(state, action) {
      state.recallData = action.payload;
    },

    clearRecall(state) {
      state.recallData = initialState.recallData;
    },
  },
});

export const { createUserRecall, clearRecall } = recallSlice.actions;
export default recallSlice.reducer;

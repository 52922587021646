import React, { useEffect, useState } from "react";
import StepForm from "./StepForm";
import QuestionComponent from "./QuestionComponent";
import { errorToast, successToast } from "../../../hooks/useToast";
import { API } from "../../../api";
import { useDispatch } from "react-redux";
import { clearRecall } from "../../../store/slices/userRecall";
import { useNavigate } from "react-router-dom";

const AttemptRecall = ({ recallData, submittedAnswers }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const length = recallData?.recallQuestions?.length;
  const dispatch = useDispatch();
  const progress = ((currentIndex + 1) / length) * 100;
  const navigate = useNavigate();
  const [timeConsumed, setTimeConsumed] = useState(null);
  const [pauseLoading, setPauseLoading] = useState(false);

  const [exitRecall, setExitRecall] = useState(false);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleQuestionChange = (index) => {
    setCurrentIndex(index);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await API.generateRecallResult({
        userRecallId: recallData?.id,
      });
      successToast(response);
      setLoading(false);
      dispatch(clearRecall());
      navigate(`/user/result-recall?id=${response?.data?.data?.id}`); //TODO: change this to recall result
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorToast(error, "Can not generate Recall Result");
    }
  };

  useEffect(() => {
    if (exitRecall && recallData?.recallMode === "timeBased") {
      handleSubmit();
    }
  }, [exitRecall]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const remainingTime = recallData?.time;

  const handleRecallPause = async () => {
    setPauseLoading(true);
    try {
      const response = await API.pauseUserRecall({
        userRecallId: recallData?.id,
        timeConsumed: timeConsumed,
      });
      successToast(response?.data?.message);
      setPauseLoading(false);
      dispatch(clearRecall());
      navigate("/user/dashboard/recall");
    } catch (error) {
      console.log(error);
      setPauseLoading(false);
      errorToast(error, "Can not pause recall");
    }
  };

  const [currentExplanation, setCurrentExplanation] = useState(null);

  useEffect(() => {
    if (
      currentIndex === 0 &&
      currentExplanation &&
      recallData?.recallMode === "explanationBased"
    ) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [currentExplanation]);

  return (
    <div className="container flex flex-col gap-12 ">
      <StepForm
        handleNext={handleNext}
        length={length}
        currentIndex={currentIndex}
        handlePrevious={handlePrevious}
        handleSubmit={handleSubmit}
        loading={loading}
        mode={recallData?.recallMode}
        value={progress}
        questionNumber={currentIndex + 1}
        questionRecallId={recallData?.recallQuestions[currentIndex]?.questionRecallId}
        totalTime={remainingTime}
        setTimeConsumed={setTimeConsumed}
        setExitRecall={setExitRecall}
        handleRecallPause={handleRecallPause}
        pauseLoading={pauseLoading}
        currentExplanation={currentExplanation}
        handleQuestionChange={handleQuestionChange}
        categoryName={recallData?.recallQuestions[currentIndex]?.categoryRecallName}
        questionDate={recallData?.recallQuestions[currentIndex]?.questionDate}
      >
        <QuestionComponent
          categoryName={recallData?.recallQuestions[currentIndex]?.categoryRecallName}
          submittedAnswers={submittedAnswers}
          totalTime={remainingTime}
          timeConsumed={timeConsumed}
          userRecallId={recallData?.id}
          data={recallData?.recallQuestions[currentIndex]}
          setTimeConsumed={setTimeConsumed}
          mode={recallData?.recallMode}
          setCurrentExplanation={setCurrentExplanation}
          questionDate={recallData?.recallQuestions[currentIndex]?.questionDate}
        />
      </StepForm>
    </div>
  );
};

export default AttemptRecall;

import { Checkbox } from "@nextui-org/react";
const mySubscriptionsRecallcolumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "recallName",
    label: "Recall",
  },
  {
    key: "subscriptionMode",
    label: "Subscription",
  },
  {
    key: "startDate",
    label: "Subscription Date",
  },
  {
    key: "endDate",
    label: "End Date",
  },
];

export { mySubscriptionsRecallcolumn };

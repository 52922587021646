import { Checkbox } from "@nextui-org/react";
const timebasedRecallcolumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "recallName",
    label: "Recall",
  },
  {
    key: "totalMcq",
    label: "Total Qs",
  },
  {
    key: "totalTime",
    label: "Total Time",
  },
  {
    key: "timeRemaining",
    label: "Remaining Time",
  },
  {
    key: "actions",
    label: "Action",
  },
];

export { timebasedRecallcolumn };

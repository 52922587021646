import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  checkd,
  circled,
  dTarget,
  newProfile,
  wavingHand,
} from "../../../assets";
import { API } from "../../../api";

const DashBoardBody = () => {
  const user = useSelector((state) => state.user.user);
  const location = useLocation();
  
  // Check if we're in recall mode by looking for  in URL
  const isRecallMode = location.pathname.includes("recall") || location.search.includes("type=recall");

  const [mySubscription, setMySubscription] = useState(null);
  const [leftAttempts, setLeftAttempts] = useState(null);

  const getData = async (req, res) => {
    try {
      // Use different API endpoints based on mode
      const response = isRecallMode 
        ? await API.getMySubscriptionRecall()
        : await API.getMySubscription();
      setMySubscription(response?.data?.data);
      
      const response1 = isRecallMode
        ? await API.attemptsLeftRecall()
        : await API.attemptsLeft();
      setLeftAttempts(response1?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [isRecallMode]); // Re-fetch when mode changes

  return (
    <>
      <div className="h-auto flex flex-col items-center bg-themeButton-0 px-5 py-6 md:block relative ">
        {" "}
        <div className="flex justify-between flex-row gap-6 lg:gap-8">
          <div className="flex flex-col gap-4 items-center md:items-start md:w-[80%]">
            <h3 className="text-white !capitalize font-bold interFont flex flex-row gap-2 items-center">
              Welcome Dr. {user?.firstName}{" "}
              <img src={wavingHand} width={40} height={40} />
            </h3>

            <div className="flex justify-center md:justify-start flex-wrap md:flex-nowrap items-center gap-3 md:gap-6 z-30 relative">
              { (isRecallMode == false || (isRecallMode === true && leftAttempts == null ) )&& (
              <Link
                to={isRecallMode ? "/user/recall" : "/user/exam"}
                className="rounded-[30px] interFont font-bold px-6 py-2 border-2 border-white text-white bg-transparent w-fit"
              >
                {isRecallMode ? "Create Recall" : "Create Exam"}
              </Link>)
              }

              {user?.freeTrial && (
                <Link
                  to={isRecallMode 
                    ? "/user/dashboard/all-subscriptions?type=recall" 
                    : "/user/dashboard/all-subscriptions"}
                  className="rounded-[30px] interFont font-bold px-6 py-2 border-2 bg-white text-themeButton-0 w-fit"
                >
                  Buy All Questions
                </Link>
              )}
            </div>

            {mySubscription && (
              <div className="w-full md:hidden bg-white flex flex-col py-2 px-2 lg:px-5 rounded-[8px] ">
                <h3 className="text-base lg:text-md font-medium text-center">
                  {user?.freeTrial
                    ? mySubscription[0]?.endDate
                      ? "Plan was expired on"
                      : "Subscription Plan"
                    : "Plan Expiring on "}
                </h3>

                <div className="flex justify-center gap-1 items-center">
                  <p className="text-themeButton-0 text-center text-sm">
                    {user?.freeTrial
                      ? mySubscription[0]?.endDate
                        ? mySubscription[mySubscription.length - 1]?.endDate
                        : "Free Trial"
                      : `${mySubscription[mySubscription.length - 1]?.endDate}`}
                  </p>

                  {leftAttempts != 0 ? (
                    <>
                      <p className="text-lg font-bold flex items-center gap-1 text-center text-themeButton-0">
                        {leftAttempts}
                        <span className="text-sm font-normal text-center text-themeButton-0">
                          {leftAttempts > 0 ? "Attempts Left" : ""}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="text-lg font-bold text-center text-themeButton-0">
                        {0}
                      </p>
                      <span className="text-sm text-center text-themeButton-0">
                        Attempts Left
                      </span>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="relative mt-0 sm:mt-6 w-full md:-mb-[20%] ">
              <div className=" hidden md:block absolute bottom-[-10%] right-[40px] z-0">
                <div className="w-[250px] h-[250px] rounded-full bg-white opacity-20 blur-[40px] animate-pulse"></div>
              </div>
              <div className="hidden md:block absolute left-[40%] bottom-[40%] z-0  ">
                <img
                  src={circled}
                  alt="circled"
                  className="w-[150px] h-[150px] "
                />
              </div>

              <div className="bg-themeSecondryDark-0 group relative z-10 py-6 md:py-10 flex flex-col gap-4 rounded-[16px] w-full px-5 mt-0 sm:mt-8 md:mt-0">
                <div className="w-full z-40 relative text-center md:text-left">
                  <h3 className="font-bold text-white capitalize interFont text-xl md:text-2xl flex flex-row items-center gap-2">
                    Achieve Success in AMC CAT Examination{" "}
                    <img
                      src={dTarget}
                      width={40}
                      height={40}
                      className="hidden md:block"
                    />
                  </h3>

                  <p className="text-white text-sm md:text-base">
                    Realtime preparation for your Australian Medical Council AMC
                    MCQ Exam.
                  </p>
                </div>

                <div className="hidden sm:block absolute bottom-[35%] md:bottom-[25%] -right-[20px] md:right-[40px] z-20">
                  <img
                    src={checkd}
                    alt="checkd"
                    className="max-w-[200px] w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex flex-col items-center gap-3 hidden w-full md:w-[20%]">
            <img
              src={user?.imageUrl || newProfile}
              alt="user profile"
              className="w-[60px] h-[60px] rounded-full"
            />

            <div className="text-center ">
              <p className="text-white text-sm">{user?.name}</p>
              <p className="text-white text-sm">{user?.email}</p>
            </div>

            {mySubscription && (
              <div className="w-full bg-white flex flex-col py-2 px-2 lg:px-5 rounded-[8px] ">
                <h3 className="text-base lg:text-md font-medium text-center">
                  {user?.freeTrial
                    ? mySubscription[0]?.endDate
                      ? "Plan was expired on"
                      : "Subscription Plan"
                    : "Plan Expiring on "}
                </h3>

                <div className="flex justify-center gap-1 items-center">
                  <p className="text-themeButton-0 text-center text-sm">
                    {user?.freeTrial
                      ? mySubscription[0]?.endDate
                        ? mySubscription[mySubscription.length - 1]?.endDate
                        : "Free Trial"
                      : `${mySubscription[mySubscription.length - 1]?.endDate}`}
                  </p>

                  {leftAttempts != 0 ? (
                    <>
                      <p className="text-lg font-bold flex items-center gap-1 text-center text-themeButton-0">
                        {leftAttempts}
                        <span className="text-sm font-normal text-center text-themeButton-0">
                          {leftAttempts > 0 ? "Attempts Left" : ""}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="text-lg font-bold text-center text-themeButton-0">
                        {0}
                      </p>
                      <span className="text-sm text-center text-themeButton-0">
                        Attempts Left
                      </span>
                    </>
                  )}
                </div>
              </div>
            )}

            <Link
              to={isRecallMode 
                ? "/user/dashboard/subscription-recall" 
                : "/user/dashboard/subscription"}
              className="text-sm lg:text-base interFont font-bold px-3 lg:px-7 py-2 bg-themeSecondry-0 text-white rounded-[50px] hover:bg-themeSecondryDark-0"
            >
              {user?.freeTrial ? "Buy Subscription " : "Upgrade Plan"}
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-[3%] relative z-[40] px-5 pb-[30px]">
        <Outlet />
      </div>
    </>
  );
};

export default DashBoardBody;
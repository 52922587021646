import React, { useEffect, useState } from "react";
import { API } from "../../api";
import { errorToast } from "../../hooks/useToast";
import Loader from "../../components/general/Loader";
import PieChart from "../../components/exam/result/PieChart";
import Statistics from "../../components/exam/result/Statistics";
import NoContentAvailable from "../../components/general/NoContentAvailable";
import { examIcon } from "../../assets";
import MetaData from "../../components/seo/MetaData";
import ProgressDashboard from "../../components/user/ProgressDashboard";
import { Button } from "@nextui-org/react";
import { useNavigate, useLocation } from "react-router-dom";

const MySummaryPage = () => {
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [statistics, getStatistics] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

      // Get subscription type from URL query params or default to "qbank"
      const searchParams = new URLSearchParams(location.search);
      const [subscriptionType, setSubscriptionType] = useState(
        searchParams.get("type") || "qbank"
      );

      // Toggle between subscription types
      const toggleSubscriptionType = (type) => {
        setSubscriptionType(type);
        navigate(`/user/dashboard/summary?type=${type}`);
      };

  const getSummery = async () => {
    let response;
    try {
      if (subscriptionType === "recall") {
        response = await API.getCorrectWrongRecall();
      } else {
        response = await API.getCorrectWrong();
      }
      setGraphData(response?.data?.data[0]);
      setLoading(false);

      if (subscriptionType === "recall") {
        response = await API.getUserDashboardStatisticsRecall();
      } else {
        response = await API.getUserDashboardStatistics();
      }
      getStatistics(response?.data?.data);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() => {
    getSummery();
  }, [subscriptionType]);

  return (
    <div className="summery flex gap-4 flex-col">
      <MetaData
        title={"Summary Dashboard - Qbank Model"}
        description={
          "View your exam summary with Qbank Model. Monitor your performance, track achievements, and analyze results."
        }
        keywords={
          " exam summary, Qbank Model results, performance tracking, exam achievements"
        }
      />

      <div className="profile-header  py-4   interFont  ">
             {/* Subscription Type Toggle */}
      <div className="container mx-auto mb-10 flex justify-center">
          <div className="bg-white rounded-full p-1 shadow-md inline-flex">
            <Button
              className={`px-6 py-2 rounded-full font-bold transition-all ${
                subscriptionType === "qbank" 
                  ? "bg-themeButton-0 text-white" 
                  : "bg-transparent text-gray-600 hover:bg-gray-100"
              }`}
              onClick={() => toggleSubscriptionType("qbank")}
            >
              Qbank
            </Button>
            <Button
              className={`px-6 py-2 rounded-full font-bold transition-all ${
                subscriptionType === "recall" 
                  ? "bg-themeButton-0 text-white" 
                  : "bg-transparent text-gray-600 hover:bg-gray-100"
              }`}
              onClick={() => toggleSubscriptionType("recall")}
            >
              Recall
            </Button>
          </div>
        </div>
        <h2 className=" text-2xl interFont capitalize font-bold ">
          Your  {subscriptionType === "recall" ? "Recall" : "Qbank"}  Progress{" "}
        </h2>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            {graphData && statistics?.length > 0 ? (
              <div className="">
                <div className="flex flex-col md:flex-row items-stretch justify-between gap-6">
                  <ProgressDashboard subscriptionType={subscriptionType} />
                  <PieChart graphData={graphData} subscriptionType={subscriptionType} />
                </div>
                <div className="">
                  <Statistics statistics={statistics} />
                </div>
              </div>
            ) : (
              <NoContentAvailable
                icon={examIcon}
                noContentText={subscriptionType === "recall" ? "Create Your First Recall" : "Create Your First Exam"}
                button={true}
                subscriptionType={subscriptionType}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MySummaryPage;

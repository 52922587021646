export const menuItems = [
  { id: "Home", label: "Home", to: "/" },
  {
    id: "/user/exam",
    label: "Free Test Drive",
    to: "/user/exam",
  },
  { id: "qbank-subscriptions", label: "Qbank Subscriptions", to: "/subscriptions", },
  // { id: "recall-subscription", label: "Recalls", to: "/subscriptions?type=recall", blinker: true, },
  { id: "recall", label: "Recalls Subscriptions", to: "/amc-recalls-subscriptions", blinker: true, },
  { id: "about", label: "About", to: "/about" },
  { id: "blogs", label: "Blog", to: "/blogs" },
  { id: "contact-us", label: "Contact us", to: "/contact-us" },
];

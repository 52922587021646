import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../api";
import PricingComponent from "../../components/pricing/PricingComponent";
import PricingComponentRecall from "../../components/pricing/PricingComponentRecall";
import { Button } from "@nextui-org/react";
import { useNavigate, useLocation } from "react-router-dom";

const DashboardSubscriptions = () => {
  const [data, setData] = useState(null);
  const authorized = useSelector((state) => state.user.user.authorized);
  const [activeSubsription, setActiveSubscription] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

    // Get subscription type from URL query params or default to "qbank"
    const searchParams = new URLSearchParams(location.search);
    const [subscriptionType, setSubscriptionType] = useState(
      searchParams.get("type") || "qbank"
    );

    
  const getData = async () => {
    let response;
    try {
            // Call different API based on subscription type
            if (subscriptionType === "recall") {
              response = await API.getAllSubscriptionRecall();
            } else {
              response = await API.getAllSubscription();
            }
      setData(response?.data?.data);

      // response = await API.getUserSummner();
      // setActiveSubscription(response?.data?.data?.userSubscriptionId);
    } catch (error) {
      console.log(error);
    }
  };

      // Update subscription type when URL changes
      useEffect(() => {
        const params = new URLSearchParams(location.search);
        const typeParam = params.get("type") || "qbank";
        setSubscriptionType(typeParam);
      }, [location.search]);

  useEffect(() => {
    getData();
  }, [subscriptionType]); // Re-fetch when subscription type changes

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const filderData = data?.filter((item) => item?.isLive);
  filderData?.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  return (
    <div>
              {filderData ? (
          subscriptionType === "recall" ? (
            <PricingComponentRecall
            hide={true}
              activeSubsription={activeSubsription}
              data={filderData}
              authorized={authorized}
              skipModel={true}
            />
          ) : (
            <PricingComponent
            hide={true}
              activeSubsription={activeSubsription}
              data={filderData}
              authorized={authorized}
              skipModel={true}
            />
          )
        ) : null}
    </div>
  );
};

export default DashboardSubscriptions;

import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@nextui-org/react";
import { API } from "../../api";
import LinkComponent from "./LinkComponent";
import { useDispatch } from "react-redux";
import { createUserRecall } from "../../store/slices/userRecall";
import InvoiceModal from "./Modal/InvoiceModal";
import ContinueRecallModal from "./Modal/ContinueRecallModal";

const TableactionsiconsRecall = ({
  id,
  resultPreview,
  deletePreview,
  handleDelete,
  invoicePreview,
  subscriptionRecallId,
  result = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [expired, setExpired] = useState(false);
  const [expiredError, setExpiredError] = useState(null);

  const getUserRecallData = async () => {
    setLoading(true);
    try {
      const response = await API.getSingleUserRecall(id);
      dispatch(createUserRecall(response?.data?.data));
      setLoading(false);
      navigate("/user/attempt/recall");
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 403) {
        setExpired(true);
        setExpiredError(error?.response?.data?.message);
      }
      console.log(error);
    }
  };

  const getUserBillInvoiceRecall = async () => {
    try {
      const response = await API.getUserBillInvoiceRecall(subscriptionRecallId);
      setInvoiceData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex justify-start items-center gap-4">
      {resultPreview && (
        <>
          {result ? (
            <>
              <LinkComponent to={`/user/result-recall?id=${result}`} text={"Result"} />
            </>
          ) : (
            <>
              <ContinueRecallModal
                error={expiredError}
                open={expired}
                setErrorModal={setExpired}
              />
              <Button
                isLoading={loading}
                variant="bordered"
                className="text-themeButton-0 border-themeButton-0"
                onClick={getUserRecallData}
              >
                Continue
              </Button>
            </>
          )}
        </>
      )}
      {deletePreview && (
        <Tooltip
          showArrow={true}
          color="danger"
          placement="left-start"
          content="Are you sure to delete this? "
        >
          <div
            onClick={() => {
              handleDelete(id);
            }}
          >
            <RiDeleteBin6Line className="text-red-500 text-2xl cursor-pointer hover:scale-150 transition-all" />
          </div>
        </Tooltip>
      )}
      {invoicePreview && (
        <InvoiceModal
          getUserBillInvoice={getUserBillInvoiceRecall}
          data={invoiceData}
        />
      )}
    </div>
  );
};

export default TableactionsiconsRecall;

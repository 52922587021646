import React, { useEffect, useState } from "react";
import Banner from "../../components/home/Banner";
import ExamGuaidComponent from "../../components/home/ExamGuaidComponent";
import { examdata } from "../../data/homepage";
import MetaData from "../../components/seo/MetaData";
import AboutHome from "../../components/home/AboutHome";
import { HomeAbout1 } from "../../assets";
import KeyFeatures from "../../components/home/KeyFeatures";
import Topics from "../../components/home/Topics";
import TestimonailSlider2 from "../../components/general/TestimonailSlider2";
import { Fade } from "react-awesome-reveal";
import PricingComponent from "../../components/pricing/PricingComponent";
import PricingComponentRecall from "../../components/pricing/PricingComponentRecall";
import { useSelector } from "react-redux";
import { API } from "../../api";
import VideoCrouserSlider from "../../components/general/VideoCrouserSlider";
import LoadModal from "../../components/general/Modal/LoadModal";
import { Button } from "@nextui-org/react";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [data, setData] = useState(null);
  const authorized = useSelector((state) => state.user.user.authorized);
  const userEmail = useSelector((state) => state.user.user?.email);
  const [activeSubsription, setActiveSubscription] = useState(null);
  // Add subscription type state
  const [subscriptionType, setSubscriptionType] = useState("qbank");

  const getData = async () => {
    let response;
    try {
      // Call different API based on subscription type
      if (subscriptionType === "recall") {
        response = await API.getAllSubscriptionRecall();
      } else {
        response = await API.getAllSubscription();
      }
      setData(response?.data?.data);

      // response = await API.getUserSummner();
      // setActiveSubscription(response?.data?.data?.userSubscriptionId);
    } catch (error) {
      console.log(error);
    }
  };

  // Toggle between subscription types
  const toggleSubscriptionType = (type) => {
    setSubscriptionType(type);
  };

  useEffect(() => {
    getData();
  }, [subscriptionType]); // Re-fetch when subscription type changes

  const filderData = data?.filter((item) => item?.isLive);
  filderData?.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  return (
    <div className="home w-full pagelayout">
      <LoadModal />
      <MetaData
        title={"Qbank Model - AMC MCQ Exam Preparation Online"}
        description={
          "Achieve AMC MCQ exam success with Qbank Model. Access high-quality exam questions, study resources, and personalized learning for medical students."
        }
        keywords={
          "AMC MCQ exam preparation, Qbank Model, medical exam questions, AMC study resources"
        }
      />
      <Banner />
      <Fade direction="bottom" triggerOnce>
        <AboutHome
          imageSrc={HomeAbout1}
          heading={
            <>
              Prepare, Master, Excel
              <br />
              Beyond simple recalls
            </>
          }
          paragh={
            "Q Bank Model is a trusted resource in Australia for AMC CAT MCQ exam preparation. Developed by AMC-passed doctors, it offers a comprehensive question bank that covers all the essential topics. The questions are up to date and carefully updated regularly to ensure accuracy and relevance. The platform provides a realistic exam experience with a user-friendly dashboard that mirrors the actual test conditions. With well-structured questions and detailed explanations, Q Bank Model helps you build the knowledge and confidence needed to succeed in your AMC MCQ Examination."
          }
        />
      </Fade>
      <VideoCrouserSlider />
      <KeyFeatures />
      {/* <ExamGuaidComponent data={examdata} /> */}
      <div className="py-12 bg-themeBackground-0">
        {/* Subscription Type Toggle */}
        <div className="container mx-auto mb-10 flex justify-center">
          <div className="bg-white rounded-full p-1 shadow-md inline-flex">
            <Button
              className={`px-6 py-2 rounded-full font-bold transition-all ${
                subscriptionType === "qbank" 
                  ? "bg-themeButton-0 text-white" 
                  : "bg-transparent text-gray-600 hover:bg-gray-100"
              }`}
              onClick={() => toggleSubscriptionType("qbank")}
            >
              Qbank Subscriptions
            </Button>
            <Button
              className={`px-6 py-2 rounded-full font-bold transition-all ${
                subscriptionType === "recall" 
                  ? "bg-themeButton-0 text-white" 
                  : "bg-transparent text-gray-600 hover:bg-gray-100"
              }`}
              onClick={() => toggleSubscriptionType("recall")}
            >
              Recalls Subscriptions
            </Button>
          </div>
        </div>
        
        {filderData ? (
          subscriptionType === "recall" ? (
            <PricingComponentRecall
              activeSubsription={activeSubsription}
              data={filderData}
              authorized={authorized}
              skipModel={userEmail && authorized ? true : false}
            />
          ) : (
            <PricingComponent
              activeSubsription={activeSubsription}
              data={filderData}
              authorized={authorized}
              skipModel={userEmail && authorized ? true : false}
            />
          )
        ) : null}
      </div>
      <Topics />

      <TestimonailSlider2 />
    </div>
  );
};

export default HomePage;
import React, { useEffect, useState } from "react";
import PricingComponent from "../../components/pricing/PricingComponent";
import PricingComponentRecall from "../../components/pricing/PricingComponentRecall";
import { API } from "../../api";
import Loader from "../../components/general/Loader";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import MetaData from "../../components/seo/MetaData";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@nextui-org/react";

const RecallPrice = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const authorized = useSelector((state) => state.user.user.authorized);
  const userEmail = useSelector((state) => state.user.user?.email);
  const [activeSubsription, setActiveSubscription] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Get subscription type from URL query params or default to "qbank"
  const searchParams = new URLSearchParams(location.search);

  const getData = async () => {
    setIsLoading(true);
    let response;
    try {
      response = await API.getAllSubscriptionRecall();

      setData(response?.data?.data);
      // response = await API.getUserSummner();
      // setActiveSubscription(response?.data?.data?.userSubscriptionId);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Update subscription type when URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const typeParam = params.get("type") || "qbank";
    
  }, [location.search]);

  useEffect(() => {
    getData();
  }, []); // Re-fetch when subscription type changes

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const filderData = data?.filter((item) => item?.isLive);
  filderData?.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  return (
    <div className="py-12 bg-themeBackground-0">
      <MetaData
        title={`Recall Qbank Model Subscriptions - AMC MCQ Exam Prep Plans`}
        description={`Select the perfect Recall subscription plan for your AMC MCQ exam prep. Flexible options to suit every medical student's needs.`}
        keywords={`Recall Model subscriptions, AMC MCQ exam plans, medical study plans, subscription options`}
      />

      {isLoading ? (
        <div className="flex justify-center py-20">
          <Loader />
        </div>
      ) : filderData ? (
        <PricingComponentRecall
          title={"Recalls Subscription Plans"}
          activeSubsription={activeSubsription}
          data={filderData}
          authorized={authorized}
          skipModel={userEmail && authorized ? true : false}
        />
      ) : null}
    </div>
  );
};

export default RecallPrice;

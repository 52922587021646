import React, { useEffect, useState } from "react";
import ProgressChart from "./ProgressChart";
import LinkComponent from "../general/LinkComponent";
import LinkComponent3 from "../general/LinkComponent3";
import { API } from "../../api";
import { useSelector } from "react-redux";

const ProgressDashboard = ({ subscriptionType = "qbank" }) =>
{
  const [data, setData] = useState(false);

  const getData = async () =>
  {
    let response;
    try
    {
      if (subscriptionType === "recall")
      {
        response = await API.userProgressStatisticsRecall();
      } else
      {
        response = await API.userProgressStatistics();
      }
      setData(response?.data?.data);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() =>
  {
    getData();
  }, [subscriptionType]);

  const user = useSelector((state) => state.user.user);

  return (
    <div className="bg-themeBackground-0  rounded-[12px] px-6 py-8 w-full  md:w-[60%]">
      <div className="header-area">
        <h2 className="text-themeSecondryDark-0 text-xl font-bold mb-6 text-start capitalize">
          Overall Performance
        </h2>
        <div className="flex flex-col md:flex-row justify-between items-center  ">
          {user?.freeTrial ? (
            <div className="w-full md:max-w-[60%] ze-box-area">
              <div className="flex justify-between flex-col sm:flex-row gap-6 mb-6 ">
                <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Questions Attempted
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.allAttempted}
                  </div>
                </div>
              </div>
              <div className="flex justify-between flex-col sm:flex-row gap-6 mb-6">
                <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Wrong Answers
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.wrongAnswers}
                  </div>
                </div>
                <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Correct Questions
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.correctAnswers}
                  </div>
                </div>
                {/* <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Pending or Incorrect
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.remainingAnswers}
                  </div>
                </div> */}
              </div>
            </div>
          ) : (
            <div className="w-full md:max-w-[60%] ze-box-area">
              <div className="flex justify-between flex-col sm:flex-row gap-6 mb-6 ">
                <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Questions Attempted
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.allAttempted}
                  </div>
                </div>
                <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Wrong Answers
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.wrongAnswers}
                  </div>
                </div>
              </div>
              <div className="flex justify-between flex-col sm:flex-row gap-6 mb-6">
                <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Correct Answers
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.correctAnswers}
                  </div>
                </div>
                <div className="flex flex-col items-center w-full  border-[1px] border-themeSecondryDark-0 rounded-[8px] p-3">
                  <h2 className="text-themeSecondryDark-0  text-base  capitalize whitespace-nowrap font-bold ">
                    Pending or Incorrect
                  </h2>
                  <div className="text-themeSecondryDark-0 text-3xl font-extrabold">
                    {data?.remainingAnswers}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="chart-ques-area flex flex-col md:flex-row gap-2 justify-between items-start">
          <div className="relative w-full md:w-[60%]">
            {!user?.freeTrial && (
              <>
                <div className="text-themeSecondryDark-0 font-bold mb-1 text-lg">
                  All Question
                </div>

                <div className="pt-9 relative">
                  <div className=" h-8 mb-2 text-xs flex rounded-[8px] bg-white   ">
                    <div
                      style={{ width: `${String(data?.percentage)}%` }}
                      className="shadow-none   flex flex-col text-center rounded-[8px] whitespace-nowrap text-white justify-center bg-themeButton-0"
                    >
                      <div
                        className="bg-themeSecondry-0 absolute top-0 left-0 transition-all duration-400 py-2 px-3 rounded-lg flex items-center justify-center value-pointer  z-20"
                        style={{ left: `calc(${data?.percentage}% - 23px)` }}
                      >
                        {data?.correctAnswers}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between text-themeSecondryDark-0 interFont font-bold">
                  <span>0</span>
                  <span>{data?.totalQuestions}</span>
                </div>
              </>
            )}

            {user?.freeTrial ? (
              <div className="mt-6">
                <LinkComponent3
                  blue={true}
                  to={subscriptionType === "recall" ? "/user/dashboard/subscription-recall" : "/user/dashboard/subscription"}
                  text={"Unlock All Questions"}
                />
              </div>
            ) : (
              <div className="mt-6">
                <LinkComponent3 to={subscriptionType === "recall" ? "/user/recall" : "/user/exam"} text={subscriptionType === "recall" ? "Create Recall" : "Create Exam"} />
              </div>
            )}
          </div>
          <div className="chart-area w-full md:w-[40%] mt-0 xl:-mt-[150px]">
            {data?.percentage && (
              <div className="w-full  ">
                <ProgressChart percentage={String(data?.percentage)} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressDashboard;

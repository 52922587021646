import React, { useEffect, useState } from "react";
import AttemptRecall from "../../components/recall/attempt/AttemptRecall";
import { useSelector } from "react-redux";
import { errorToast } from "../../hooks/useToast";
import { API } from "../../api";
import { useNavigate } from "react-router-dom";

const AttemptRecallPage = () => {
  const recallData = useSelector((state) => state.recall.recallData);
  const [submittedAnswers, setSubmittedAnswers] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await API.getPreviouslySubmittedRecallAnswers(recallData?.id);
      setSubmittedAnswers(response?.data?.data);
    } catch (error) {
      console.log(error);
      errorToast(error, "Can not fetch answers");
    }
  };
  useEffect(() => {
    if (!recallData) {
      navigate("/user/dashboard/recall");
    } else {
      getData();
    }
  }, []);

  return (
    <div className="w-full md:bg-themeGray-0 rounded-lg">
      <div className="max-w-[1100px] w-full mx-auto ">
        <AttemptRecall recallData={recallData} submittedAnswers={submittedAnswers} />
      </div>
    </div>
  );
};

export default AttemptRecallPage;

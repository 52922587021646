import React, { useEffect, useState } from "react";
import RadioButtonRecall from "../../general/RadioButtonRecall";
import { useForm } from "react-hook-form";
import { errorToast } from "../../../hooks/useToast";
import { API } from "../../../api";
import Loader from "../../general/Loader";
import { Chip } from "@nextui-org/react";
import { Editor } from "primereact/editor";
import { format } from "date-fns";

const QuestionComponent = ({
  data,
  userRecallId,
  mode,
  setTimeConsumed,
  timeConsumed,
  submittedAnswers,
  setCurrentExplanation,
  categoryName,
  questionDate,
}) => {
  const { register, handleSubmit, watch, setValue, reset, getValues } =
    useForm();
  const [loader, setLoader] = useState(false);
  const [results, setResults] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const selectedOption = watch(`option_${data?.id}`);

  const onSubmit = async (formData) => {
    let payload;
    if (mode === "timeBased") {
      payload = {
        userRecallId: Number(userRecallId),
        recallQuestionsId: Number(data?.id),
        correctOptionId: Number(formData[`option_${data?.id}`]),
        timeConsumed: Number(timeConsumed),
      };
    } else {
      payload = {
        userRecallId: Number(userRecallId),
        recallQuestionsId: Number(data?.id),
        correctOptionId: Number(formData[`option_${data?.id}`]),
      };
    }
    setLoader(true);
    try {
      const response = await API.submitUserRecallAnswer(payload);
      setResults((prevResults) => ({
        ...prevResults,
        [data?.id]: response?.data?.data,
      }));
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [data?.id]: formData[`option_${data?.id}`],
      }));
      setLoader(false);
      setTimeConsumed(0);
    } catch (error) {
      console.log(error);
      setLoader(false);
      errorToast(error, "Cannot submit answer");
    }
  };

  useEffect(() => {
    if (selectedOption) {
      handleSubmit(onSubmit)();
    }
  }, [selectedOption]);

  useEffect(() => {
    const currentAnswer = submittedAnswers?.find(
      (answer) => answer?.recallQuestionsId === data?.id
    );
    if (currentAnswer) {
      setResults((prevResults) => ({
        ...prevResults,
        [data.id]: currentAnswer,
      }));
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [data.id]: currentAnswer.userSubmittedOption,
      }));
    }
  }, [data?.id, setValue, submittedAnswers]);

  const currentResult = results[data?.id];

  useEffect(() => {
    setCurrentExplanation(results[data?.id]);
  }, [currentResult]);

  return (
    <div className="min-h-[45vh] h-full">
      <div className="hidden md:flex flex-wrap gap-3 mb-3 items-center">
        {categoryName && (
          <div className="flex   backdrop: items-center     px-1 py-1 rounded-full">
            <span className=" font-bold text-themeSecondry-0 mr-2">
              Category:
            </span>
            <p className="font-medium text-white text-sm whitespace-nowrap px-5 py-1 bg-themeSecondryDark-0 rounded-[4px]">
              {categoryName}
            </p>
          </div>
        )}


{questionDate && (
          <div className="flex  items-center      px-1 py-1 rounded-full">
            <span className=" font-bold text-themeSecondry-0 mr-2">Month & Year:</span>
            <div className="w-full border border-themeButton-0 rounded-[4px] px-3 py-1">
              <p className="text-themeButton-0 font-medium text-sm">
              {format(new Date(questionDate), "MMMM yyyy")}
              </p>
            </div>
          </div>
        )}

        {/* <div className="  h-[1px] bg-gray-500 w-full"></div> */}
      </div>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Editor
          value={data?.questionRecallName}
          readOnly
          style={{ height: "100%", userSelect: "none" }} // Disable text selection
          headerTemplate={<></>}
        />

        {/* {loader ? (
          <Loader />
        ) : ( */}
        <RadioButtonRecall
          radioOptions={data?.questionRecallOptions}
          register={register}
          name={`option_${data?.id}`}
          result={currentResult}
          mode={mode}
          selectedOptions={selectedOptions}
          setValue={setValue}
        />
        {/* )} */}
      </form>
    </div>
  );
};

export default QuestionComponent;

import * as yup from "yup";
const recallModeSchema = yup.object().shape({
  typeOfQuestions: yup.string().required("Please Select type of Questions"),
  recallMode: yup.string().required("Please Select mode of your Recall"),
  questionsPerRecall: yup
    .string()
    .required("Please select how much questions you want to attempt"),
  difficultyMode: yup
    .string()
    .required("Please Select difficulty level of your recall"),
});

export { recallModeSchema };

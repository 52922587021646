import React, { useEffect, useState } from "react";
import Result from "../../components/recall/result/Result";
import { useQuery } from "../../hooks/queryParam";
import { errorToast } from "../../hooks/useToast";
import { API } from "../../api";
import Loader from "../../components/general/Loader";

const ResultRecallPage = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState(null);

  const getData = async () => {
    try {
      const response = await API.getUserRecallResult(id);
      setData(response?.data?.data);
    } catch (error) {
      console.log(error);
      errorToast(error, "Can not fetch result");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="max-w-[1100px] px-3 py-12  w-full mx-auto rounded-lg">
      {data ? <Result data={data} /> : <Loader />}
    </div>
  );
};

export default ResultRecallPage;

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
} from "@nextui-org/react";
import { TbMessageReport } from "react-icons/tb";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../general/ButtonComponent";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedback } from "../../../validations/feedBack";
import { errorToast, successToast } from "../../../hooks/useToast";
import { API } from "../../../api";
import RadioButton from "../../general/RadioButton";
import { reportArray } from "../../../data/userReport";
import { reportSchema } from "../../../validations/report";
import RadioReport from "./RadioReport";

const ReportQuestionRecallModal = ({ questionRecallId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(reportSchema) });

  const [ipAddress, setIpAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  const getIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      setIpAddress(response?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getIp();
  }, []);

  const onSubmit = async (data) => {
    const payload = {
      reportOptions: data?.reportOptions,
      detail: data?.detail,
      ip: ipAddress,
      questionRecallId: questionRecallId,
    };
    setLoading(true);

    try {
      const response = await API.registerRecallReport(payload);
      successToast(response?.data?.message);
      reset();
      setLoading(false);
    } catch (error) {
      reset();
      setLoading(false);
      errorToast(error, "can not submit feedback");
    }
    onClose();
  };

  return (
    <>
      <Button
        onPress={onOpen}
        startContent={<TbMessageReport />}
        variant="bordered"
        className="border-themeButton-0 text-themeButton-0  !opacity-100 text-[13px]  !py-[10px] !rounded-[8px]  w-full md:w-fit font-semibold"
      >
       Report This Question
      </Button>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader className="px-3"> Report This Question</ModalHeader>
          <ModalBody>
            <form
              className="grid grid-col-1 gap-8 px-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="w-full">
                <RadioReport
                  radioOptions={reportArray}
                  register={register}
                  name={"reportOptions"}
                 />
                {errors && errors?.reportOptions && (
                  <p className="text-tiny text-danger   mt-1">
                    {errors?.reportOptions?.message}
                  </p>
                )}
              </div>

              <div className="w-full">
                <Textarea
                  label="Report"
                  placeholder="share your concerns"
                  className="w-full"
                  {...register("detail")}
                />
                {errors && errors?.detail && (
                  <p className="text-tiny text-danger   mt-1">
                    {errors?.detail?.message}
                  </p>
                )}
              </div>

              <ModalFooter>
                <ButtonComponent
                  type={"submit"}
                  text="Submit"
                  loading={loading}
                  isActive={true}
                />
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportQuestionRecallModal;

import React from "react";
import SelectComponent from "../general/SelectComponent";
import {
  difficultyMode,
  disabledKeyNumberQ,
  disabledKeyTypesQ,
  recallMode,
  questionsPerRecall,
  questionsPerRecallFree,
  typeOfQuestions,
  typeOfQuestionsFree,
} from "../../data/recallModes";
import { Link } from "react-router-dom";

const RecallModes = ({ register, errors, freeTrial, attempts }) => {
  return (
    <div className="flex flex-col gap-4 py-4">
   {freeTrial && (
  <>
    {attempts >= 1 ? (
      <div className="p-4 bg-themeBackground-0 border border-blue-300 rounded-lg  ">
        <p className="text-center font-medium text-[14px] text-themeSecondry-0">
          You have used {5 - attempts} out of 5 attempts from  your free trial.
          <br />
          To unlock unlimited attempts,{" "}
          <span>
            <a href="/subscriptions" className="text-blue-900 underline hover:text-blue-600 transition duration-200">
              Buy Subscription
            </a>
          </span>
        </p>
      </div>
    ) : (
      <div className="p-4 bg-red-50 border border-red-300 rounded-lg  ">
        <p className="text-center font-medium text-[14px] text-red-700">
        To start using the service,{" "}
          <span>
            <a href="/subscriptions" className="text-red-900 underline hover:text-red-600 transition duration-200">
              Buy Subscription
            </a>
          </span>
        </p>
      </div>
    )}
  </>
)}


      <SelectComponent
        freeTrial={false}
        data={false ? typeOfQuestionsFree : typeOfQuestions}
        placeholder={"Select Type of Questions"}
        register={register}
        errors={errors}
        label={"Type Of Questions"}
        name="typeOfQuestions"
        disabledKeys={freeTrial ? disabledKeyTypesQ : null}
      />
      <SelectComponent
        data={recallMode}
        register={register}
        placeholder={"Select Mode of Your Recall"}
        errors={errors}
        label={"Recall Mode"}
        name="recallMode"
      />
      <SelectComponent
        data={difficultyMode}
        register={register}
        placeholder={"Select Difficulty Level"}
        errors={errors}
        label={"Difficulty Level"}
        name="difficultyMode"
      />
      <SelectComponent
        freeTrial={false}
        data={false ? questionsPerRecallFree : questionsPerRecall}
        register={register}
        placeholder={"Select Questions Per Recall"}
        errors={errors}
        label={"Questions Per Recall"}
        name="questionsPerRecall"
        disabledKeys={freeTrial ? disabledKeyNumberQ : null}
      />
    </div>
  );
};

export default RecallModes;

import React from "react";
import { Comment } from "react-loader-spinner";
import LinkComponent from "./LinkComponent";

const NoContentAvailable = ({ noContentText, icon, button , subscriptionType = "qbank"}) => {
  return (
    <div className="flex flex-col items-center justify-center h-[40vh]  bg-themeGray-0 p-8 rounded-lg w-full ">
      {icon ? (
        <img src={icon} alt="icon" className="w-[70px]" />
      ) : (
        <Comment
          visible={true}
          height="80"
          width="80"
          ariaLabel="comment-loading"
          wrapperStyle={{}}
          wrapperClass="comment-wrapper"
          color="#fff"
          backgroundColor="#C70048"
        />
      )}
      <p className="mt-4 text-gray-700 font-medium dark:text-gray-300">
        {noContentText}
      </p>

      <div className="mt-8">
        {button && <LinkComponent text={subscriptionType == "recall" ? "Create Recall" : "Create Exam"} to={ subscriptionType == "recall" ? "/user/recall" : "/user/exam"} />}
      </div>
    </div>
  );
};

export default NoContentAvailable;

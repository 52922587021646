import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
} from "@nextui-org/react";
import { VscFeedback } from "react-icons/vsc";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../general/ButtonComponent";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedback } from "../../../validations/feedBack";
import { errorToast, successToast } from "../../../hooks/useToast";
import { API } from "../../../api";

const StarRating = ({ selectedStars, onStarClick }) => {
  const totalStars = 5;

  const handleStarClick = (starIndex) => {
    onStarClick(starIndex + 1);
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => (
        <span
          key={index}
          onClick={() => handleStarClick(index)}
          className="text-3xl pr-3 "
          style={{
            cursor: "pointer",
            color: selectedStars > index ? "#ffc107" : "#e4e5e9",
          }}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

const FeedBackRecallModal = ({ questionRecallId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStars, setSelectedStars] = useState(0);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(feedback) });

  const handleStarClick = (stars) => {
    setSelectedStars(stars);
    setValue("stars", stars);
  };

  const [ipAddress, setIpAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  const getIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      setIpAddress(response?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getIp();
  }, []);

  const onSubmit = async (data) => {
    const payload = {
      detail: data?.detail,
      ip: ipAddress,
      star: Number(data?.stars),
      questionRecallId: questionRecallId,
    };
    setLoading(true);

    try {
      const response = await API.registerFeedBackRecall(payload);
      successToast(response?.data?.message);
      reset();
      setSelectedStars(0);
      setLoading(false);
    } catch (error) {
      reset();
      setSelectedStars(0);
      setLoading(false);
      errorToast(error, "can not submit feedback");
    }

    onClose();
  };

  return (
    <>
      <Button
        onPress={onOpen}
        startContent={<VscFeedback />}
        variant="bordered"
        className="border-themeButton-0 text-themeButton-0 !opacity-100 text-[13px] md:px-6 !py-[10px] !rounded-[8px]  w-full md:w-fit font-semibold"
      >
        Feedback
      </Button>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader className="px-3">Feedback</ModalHeader>
          <ModalBody>
            <form
              className="grid grid-col-1 gap-2 px-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex justify-start items-center gap-5">
                <p>Overall:</p>
                <StarRating
                  selectedStars={selectedStars}
                  onStarClick={handleStarClick}
                />
              </div>
              {errors && errors?.stars && (
                <p className="text-tiny text-danger   mt-1">
                  {errors?.stars?.message}
                </p>
              )}

              <Textarea
                label="Feedback"
                placeholder="Enter your feedback"
                className="w-full"
                {...register("detail")}
              />
              {errors && errors?.detail && (
                <p className="text-tiny text-danger   mt-1">
                  {errors?.detail?.message}
                </p>
              )}

              <ModalFooter>
                <ButtonComponent
                  type={"submit"}
                  text="Submit"
                  loading={loading}
                  isActive={true}
                />
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FeedBackRecallModal;

import { Checkbox } from "@nextui-org/react";
const explanationBasedRecallcolumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "recallName",
    label: "Recall",
  },
  {
    key: "totalMcq",
    label: "Total Qs",
  },
  
  {
    key: "actions",
    label: "Action",
  },
];

export { explanationBasedRecallcolumn };
